<template>
  <v-dialog persistent 
    v-model="activeModal"
    max-width="650px"
    
    class="modal-cadastro">
    <template v-slot:activator="{}">
      <v-btn
        tile
        :color="variables.colorPrimary"
        dark
        class="mb-2 modal-cadastro-btn-cadastrar"
        @click="openModal"
        :loading="loading"
        v-if="permiteAcao($route, 'add')">Novo cadastro</v-btn>
    </template>
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
          <v-spacer></v-spacer>
          <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>
      

      <v-form>
        <v-card-title class="modal-cadastro-titulo">
          {{ isEdit ? 'Editar Saída' : 'Cadastrar Saída' }}
        </v-card-title>

        <v-card-text class="modal-cadastro-form">
          <v-row>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="3">
              <v-currency-field v-model="item.value" :label="'Valor'" :prefix="'R$'" :error-messages="valueErros" />
            </v-col>

            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4">
              <v-menu
                v-model="menuDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="computedDateFormatted"
                    label="Data"
                    single-line
                    v-on:keyup.delete="clearDate()"
                    v-on="on"
                    readonly
                    :error-messages="dateErros"
                  >
                    <template v-slot:prepend>
                      <v-icon>
                        event
                      </v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="item.dtReference"
                  no-title
                  scrollable
                  locale="pt-br"
                  :color="variables.colorPrimary">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="clearDate()">Limpar</v-btn>
                    <v-btn text color="primary" @click="closeDate">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="5">
              <v-select
                v-model="item.type"
                :items="listSelectTypes"
                menu-props="auto"
                hide-details
                label="Tipo de despesa"
                single-line />
            </v-col>

            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="12">
              <v-text-field
                v-model="item.description"
                label="Descrição"
                single-line
                :error-messages="descriptionErros" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="modal-cadastro-footer">
          <v-btn :color="variables.colorPrimary" @click="closeModal" class="br-btn br-btn-cancelar">Cancelar</v-btn>
          <v-btn type="submit" :color="variables.colorPrimary" @click.prevent="submit" class="br-btn" :loading="loading">{{ isEdit ? 'Salvar' : 'Cadastrar' }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, minValue } from 'vuelidate/lib/validators'
import Events from '@/core/service/events'
import variables from '@/assets/styles/helpers/_variables.scss'
import moment from 'moment'

import validacao from './validacao'
const namespaceStore = 'despesas'

export default {
  name: 'ModalDespesas',
  mixins: [validationMixin],
  data: () => ({
    activeModal: false,
    loading: false,
    menu: false,
    menuDate: false
  }),
  
  watch: {
    // 'item.dtReference' (val) {
    //   this.dtStart = this.formatDate(val)
    // }
  },

  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters(namespaceStore, ['item']),
    ...validacao,
    variables: () => variables,
    isEdit () {
      return this.item.id ? !!1 : !!0
    },

    computedDateFormatted () {
      return this.formatDate(this.item.dtReference)
    },

    listSelectTypes () {
      return [
        'Despesa', 'Aporte'
      ]
    }
  },
  mounted () {
    Events.$on('cadastro::openModalCadastro', () => {
      this.activeModal = true
    })
  },
  methods: {
    ...mapActions(namespaceStore, ['cadastrarItem', 'limparItem', 'getItens', 'editarItem']),
    openModal () {
      this.activeModal = true
    },

    closeModal () {
      this.activeModal = false
      this.limparItem()
      this.$v.item.$reset()
    },

    clearDate () {
      this.item.dtReference = ''
    },

    closeDate () {
      this.menuDate = false
    },

    formatDate (date) {
      moment().locale(this.$store.getters.Locale)
      if (!date) return null
      return moment(date).format('DD/MM/YYYY')
    },

    submit () {
      if (this.$v.item.$invalid) { 
        this.$v.item.$touch()
        return false 
      }

      this.loading = true

      const dados = {
        value: this.item.value,
        dtReference: this.item.dtReference,
        description: this.item.description,
        type: this.item.type,
      }

      if (this.item.id) {
        dados.id = this.item.id

        this.editarItem(dados).then(() => {
          this.getItens()
          this.closeModal()
          const msg = {
            toggle: true,
            type: 'success',
            msg: 'Edição realizado com sucesso!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
        }).catch(err => {
          const msg = {
            toggle: true,
            type: 'error',
            msg: err.msg
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
        .finally(() => this.loading = false)
      } else {
        this.cadastrarItem(dados).then(() => {
          this.getItens()
          this.closeModal()
          const msg = {
            toggle: true,
            type: 'success',
            msg: 'Cadastro realizado com sucesso!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
        }).catch(err => {
          const msg = {
            toggle: true,
            type: 'error',
            msg: err.error
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
        .finally(() => this.loading = false)
      }

    },
  },

  validations () {
    return  {
      item: {
        value: { required, minValue: minValue(0.1) },
        dtReference: { required },
        description: { required },
      }
    }
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/formularios';
  @import '~@/assets/styles/components/modal-cadastro';
</style>