export default {
  valueErros () {
    const errors = []
    if (!this.$v.item.value.$dirty) return errors
    !this.$v.item.value.required && errors.push('Valor é obrigatório')
    !this.$v.item.value.minValue && errors.push('Valor é obrigatório')
    return errors
  },

  dateErros () {
    const errors = []
    if (!this.$v.item.value.$dirty) return errors
    !this.$v.item.dtReference.required && errors.push('Data é obrigatório')
    return errors
  },

  descriptionErros () {
    const errors = []
    if (!this.$v.item.value.$dirty) return errors
    !this.$v.item.description.required && errors.push('Descrição é obrigatório')
    return errors
  }
}
